import { usePlayerPageAnalytics } from '@package/sdk/src/analytics';
import type { Channel, TVChannelAccessKind } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';

import { SessionGetters, SessionState, storeToRefs, useAuthStore, useSessionStore } from '../../../stores/';
import { useAuthActions } from '../../auth/composables/use-auth-actions';
import { analyticService, routerService } from '../../index';
import { translate } from '../../localization/translate';
import { useOfferActions } from '../../offers/composables/use-offer-actions';
import { RouterPage } from '../../router/router';

export function useTvChannelActions() {
  const authStore = useAuthStore();
  const sessionStore = useSessionStore();

  const { isAuth, isActiveSubscription } = storeToRefs<SessionState, SessionGetters, unknown>(sessionStore);
  const { openOffersPage } = useOfferActions();
  const { openAuthPage } = useAuthActions();

  const playerPageAnalytics = usePlayerPageAnalytics(analyticService.sender);

  const isTvChannelPlaybackAvailable = (channel: Channel) => {
    const { accessKind } = channel;

    return isActiveSubscription.value || (isAuth.value && accessKind === 'register') || accessKind === 'all_users';
  };

  const openTvChannelPage = (channel: Channel) => {
    const { accessKind } = channel;

    const resolved = routerService.resolve({ name: RouterPage.ChannelsPage });

    const isPlaybackAvailable = isTvChannelPlaybackAvailable(channel);
    // Если юзер без авторизации, и канал доступен после реги.
    if (!isAuth.value && accessKind === 'register') {
      authStore.setBackUrl(resolved.fullPath);

      return openAuthPage();
    }

    // Если в целом нельзя посмотреть
    if (!isPlaybackAvailable) {
      authStore.setBackUrl(resolved.fullPath);

      return openOffersPage();
    }

    const channelRoute = { name: RouterPage.ChannelsPlayerPage, params: { id: channel.id } };

    playerPageAnalytics.onGotoTvPlayerPage({
      channelTitle: channel.title,
    });

    // значит все ок, уводим на ТВ
    return routerService.push(channelRoute);
  };

  const getTvChannelStickerText = (accessKind: TVChannelAccessKind) => {
    if (isActiveSubscription.value) {
      return '';
    }

    // если доступен для всех юзеров
    if (accessKind === 'all_users') {
      return translate('pages.channels.watchNow');
    }

    // Доступен после реги
    if (isAuth.value && accessKind === 'register') {
      return translate('pages.channels.watchNow');
    }

    // Есть рега, но нет активной подписки
    if (isAuth.value && !isActiveSubscription.value && accessKind === 'subscription') {
      return translate('pages.channels.subscription');
    }

    // Если нет авторизации, и канал доступен после реги
    if (!isAuth.value && accessKind === 'register') {
      return translate('pages.channels.signUpAndWatch');
    }

    // Если без авторизации, и канал только по подписке
    if (!isAuth.value && accessKind === 'subscription') {
      return '';
    }

    throw new UnexpectedComponentStateError('accessKind');
  };

  return {
    getTvChannelStickerText,
    isTvChannelPlaybackAvailable,
    openTvChannelPage,
  };
}
