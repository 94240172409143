<template>
  <slot></slot>
</template>

<script lang="ts" setup>
import { IDisposable } from '@gomarky/markybox-core/lib/types/base/disposable';
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import { DisposableStore, UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { TimeSeconds } from '@PLAYER/player/base/number';
import useTranslationMediaAnalytics from '@PLAYER/player/modules/analytics/use-translation-media-analytics';
import useDVR from '@PLAYER/player/modules/hooks/use-dvr';
import type { UseDetectMediaTechSupportResult } from '@PLAYER/player/modules/hooks/use-native-playback-support';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import type { PlayerProjector } from '@PLAYER/player/modules/instance/interfaces';
import useSafeMediaPlayerPlugins from '@PLAYER/player/modules/plugin/use-safe-media-player-plugins';
import useConfigStore from '@PLAYER/player/modules/store/config-store';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import { MediaElementDOMInstance } from '@PLAYER/player/modules/video/media-element-dom-instance';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import AvplayMediaTech from '@PLAYER/player/tech/avplay/avplay-instance';
import DashMediaTech from '@PLAYER/player/tech/dash/dash-instance';
import HlsMediaTech from '@PLAYER/player/tech/hls/hls-instance';
import Html5MediaTech from '@PLAYER/player/tech/html5/html5-instance';
import MediaSourceTech from '@PLAYER/player/tech/media-source-tech';
import ShakaPlayerMediaTech from '@PLAYER/player/tech/shaka/shaka-player-instance';
import TataudioMediaSourceTech from '@PLAYER/player/tech/tataudio/tataudio-instance';
import type { HlsConfig } from 'hls.js';
import { storeToRefs } from 'pinia';
import { markRaw, onBeforeUnmount, onMounted } from 'vue';

const props = withDefaults(
  defineProps<{
    videoEl: MediaElementDOMInstance;
    onSetMediaSourceTech: (instance: MediaSourceTech) => void;
    techPlaybackType: UseDetectMediaTechSupportResult;
    autoStartLoad?: boolean;
  }>(),
  {
    autoStartLoad: false,
  },
);

const logger = useLogger('ProvideMediaSourceContext.vue', 'media-player');
const { isVOD, isLive, projector, isMyChannelPlayer, isContentBackgroundPlayer, isSmartTvMainPagePlayer } =
  useProjector();
const { isSmartTV } = usePlatform();
const { manifestUrl, manifestStartOffset, autoplayManifestStartOffset } = storeToRefs(useManifestStore());
const { initialQualityLevel } = storeToRefs(useConfigStore());
const videoInteractions = useVideoInteractions();
const { isDVR } = useDVR();
const translationMediaAnalytics = useTranslationMediaAnalytics();

const plugins = useSafeMediaPlayerPlugins();

const disposableStore = new DisposableStore();

// Начинаем сразу подгружать видео, если это НЕ кином.
const autoStartLoad = (() => {
  if (isSmartTV) {
    return true;
  }

  if (props.autoStartLoad) {
    return true;
  }

  return isVOD.value || isLive.value;
})();

type BufferRecordType = {
  bufferingGoalBefore: TimeSeconds;
  bufferingGoalAfter: TimeSeconds;
  rebufferingGoal: TimeSeconds;
};

const kinomBufferMap: BufferRecordType = {
  bufferingGoalBefore: isSmartTV ? 3 : 6,
  bufferingGoalAfter: isSmartTV ? 6 : 24,
  rebufferingGoal: 1,
};

const bufferSizeProjectorMap: Record<PlayerProjector, BufferRecordType> = {
  vod: {
    bufferingGoalBefore: isSmartTV ? 12 : 18,
    bufferingGoalAfter: isSmartTV ? 18 : 96,
    rebufferingGoal: isSmartTV ? 2 : 2,
  },
  live: {
    bufferingGoalBefore: isSmartTV ? 12 : isDVR.value ? 18 : 12,
    bufferingGoalAfter: isSmartTV ? 18 : isDVR.value ? 60 : 18,
    rebufferingGoal: isSmartTV ? 2 : 3,
  },
  kinom: kinomBufferMap,
  playlist: kinomBufferMap,
  'smarttv-main-page-player': kinomBufferMap,
  'my-channel': kinomBufferMap,
  'content-background': kinomBufferMap,
};

const videoMaxHeightLevelMap: Record<PlayerProjector, number> = {
  vod: Infinity,
  live: Infinity,
  kinom: isSmartTV ? 500 : 800,
  'content-background': isSmartTV ? 500 : 800,
  'smarttv-main-page-player': isSmartTV ? 500 : 800,
  'my-channel': 800,
  playlist: Infinity,
};

const mediaSourceTech: MediaSourceTech = (() => {
  const { bufferingGoalAfter, bufferingGoalBefore, rebufferingGoal } = bufferSizeProjectorMap[projector.value];
  const liveTimeoutEdgeSeconds = ConstantsConfigPlayer.getProperty('liveTimeoutEdgeSeconds');

  if (!manifestUrl.value || props.techPlaybackType === 'html5') {
    return new Html5MediaTech();
  }

  if (props.techPlaybackType === 'shaka') {
    // @ts-expect-error
    const shakaConfig: Partial<shaka.extern.PlayerConfiguration> = {
      restrictions: {
        maxHeight: videoMaxHeightLevelMap[projector.value],
      },
      abr: {
        clearBufferSwitch: isSmartTV,
      },
      manifest: {
        hls: { liveSegmentsDelay: ConstantsConfigPlayer.getProperty('liveSegmentsDelay') },
      },
      streaming: {
        bufferingGoal: bufferingGoalAfter,
        bufferBehind: bufferingGoalBefore,
        rebufferingGoal,
      },
    };

    return new ShakaPlayerMediaTech({ shakaConfig });
  }

  if (props.techPlaybackType === 'avplayer') {
    return new AvplayMediaTech();
  }

  if (props.techPlaybackType === 'tataudio') {
    return new TataudioMediaSourceTech();
  }

  if (props.techPlaybackType === 'dash.js') {
    return new DashMediaTech();
  }

  if (props.techPlaybackType === 'hls.js') {
    const hlsConfig: Partial<HlsConfig> = {
      autoStartLoad,
      backBufferLength: bufferingGoalBefore,
      maxBufferLength: bufferingGoalAfter,
    };

    if (isLive.value) {
      hlsConfig.liveSyncDuration = liveTimeoutEdgeSeconds;
    }

    return new HlsMediaTech({ hlsConfig });
  }

  throw new UnexpectedComponentStateError('mediaSourceTech');
})();

markRaw(mediaSourceTech);
Reflect.apply(props.onSetMediaSourceTech, undefined, [mediaSourceTech]);

disposableStore.add(mediaSourceTech);

const initPlugins = () => {
  const onPlaybackInterrupted = () => translationMediaAnalytics.onPlayerInterrupted();
  const onPlaybackFailed = () => translationMediaAnalytics.onPlayerFatalError();

  // оборачиваем вызов плагинов наших в try/catch, чтобы ничего не поломать
  try {
    for (const Plugin of plugins) {
      const tech = (() => {
        if (mediaSourceTech instanceof Html5MediaTech) {
          return mediaSourceTech.mediaEl;
        }

        if (mediaSourceTech instanceof HlsMediaTech) {
          return mediaSourceTech.hlsInstance;
        }

        if (mediaSourceTech instanceof ShakaPlayerMediaTech) {
          return mediaSourceTech.shakaInstance;
        }
      })();

      if (!tech) {
        throw new UnexpectedComponentStateError('tech');
      }

      const plugin = new Plugin(props.techPlaybackType as 'html5' | 'hls.js' | 'shaka', tech);

      plugin.emitter.on('playback-failed', onPlaybackFailed);
      plugin.emitter.on('playback-interrupted', onPlaybackInterrupted);

      disposableStore.add(plugin as IDisposable);
    }
  } catch (error) {
    logger.error(error);
  }
};

const initMediaSourceTech = async () => {
  const { videoEl } = props;

  await mediaSourceTech.init();
  await mediaSourceTech.attachMedia(videoEl.domElement as HTMLVideoElement);

  initPlugins();

  translationMediaAnalytics.onPlayerInitialized();

  await mediaSourceTech.loadSource({
    src: manifestUrl.value,
    offset: manifestStartOffset.value,
    initialQualityLevel: initialQualityLevel.value,
  });

  videoInteractions.changeCurrentTime({ manually: true, seconds: manifestStartOffset.value });

  if (autoStartLoad && autoplayManifestStartOffset.value) {
    videoInteractions.play();
  }
};

onMounted(() => {
  if (!manifestUrl.value) {
    return;
  }

  return initMediaSourceTech();
});

onBeforeUnmount(() => disposableStore.dispose());
</script>
